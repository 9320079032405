import React, { createContext, useContext, ReactNode } from "react"
import { app } from "./firebaseConfig"
import { FirebaseApp } from "firebase/app"

interface FirebaseContextType {
  firebaseApp: FirebaseApp
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(
  undefined
)

interface FirebaseProviderProps {
  children: ReactNode
}

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({
  children,
}) => {
  return (
    <FirebaseContext.Provider value={{ firebaseApp: app }}>
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => {
  const context = useContext(FirebaseContext)
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider")
  }
  return context
}

export { FirebaseContext }
