import React from "react"
import { LayoutWrapper } from "./src/components/Layout/LayoutWrapper"
import { I18nextProvider } from "react-i18next"
import { i18nClient } from "./src/i18n/config"

export const wrapRootElement = ({ element, props }) => {
  // console.error = error => {
  //   if (error.includes("Hydration failed")) {
  //     return
  //   } else {
  //     // Log other errors as usual
  //     console.error(error)
  //   }
  // }
  return (
    <I18nextProvider i18n={i18nClient}>
      <LayoutWrapper {...props}>{element}</LayoutWrapper>
    </I18nextProvider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return <LayoutWrapper {...props}>{element}</LayoutWrapper>
}
