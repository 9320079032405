import accounting from "accounting"

const regions = [
  "us",
  "eea",
  "br",
  "sg",
  "au",
  "tr",
  "fr",
  "ca",
  "it",
  "uk",
  "kr",
  "ae",
]

export const getRegionPath = (path: string) => {
  const region = regions.find(region => {
    return region === "ca"
      ? path === "/ca" || path === "/ca/" || path.match(/^\/ca\/.*/)
      : path.match(new RegExp(`^/${region}`))
  })
  return region ?? ""
}

export const removeTrailingSlash = (path: string) => {
  return path.replace(/\/+$/, "")
}

export const formatFiat = amount => {
  return formatNumber(amount, true)
}

export const formatNumber = (amount, isFiat = true) => {
  const setting = isFiat ? fiatDecimalSetting : cryptoDecimalSetting
  const decimal = Math.abs(amount) >= 1 ? setting.min : setting.max
  return accounting.formatNumber(amount, decimal)
}

export const formatInterestNumber = amount => {
  const setting = interestDecimalSetting
  const decimal =
    Math.abs(amount) >= setting.threshold ? setting.min : setting.max
  return accounting.formatNumber(amount, decimal)
}

export function formatNumberDecimal(amount, decimalPlaces) {
  return accounting.formatNumber(amount, decimalPlaces)
}

export function formatPercentage(amount) {
  const decimal = Math.abs(amount) >= 1000 ? 0 : 2
  return formatNumberDecimal(amount, decimal)
}

export const toPercentage = amount => {
  return amount * 100
}

export function toShortFormat(amount) {
  if (amount == null) {
    return "N/A"
  }
  if (amount / 1000000000 >= 1) {
    return `${formatNumber(amount / 1000000000)}B`
  }
  if (amount / 1000000 >= 1) {
    return `${formatNumber(amount / 1000000)}M`
  }
  return formatNumber(amount)
}

export const roundMAxFourDigits = (amount: string) => {
  if (amount.indexOf(".") > 3) {
    return amount.split(".")[0]
  }
  return amount
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const interestDecimalSetting = {
  threshold: 100,
  min: 0,
  max: 2,
}

const fiatDecimalSetting = {
  min: 2,
  max: 6,
}

const cryptoDecimalSetting = {
  min: 2,
  max: 8,
}

export const i18nLanguageToDisplayString = (language: string) => {
  switch (language) {
    case "da":
      return "Dansk"
    // case "de":
    //   return "Deutsch"
    case "en":
      return "English"
    case "en-US":
      return "English"
    case "es":
      return "Español"
    case "fr":
      return "Français"
    case "fr-CA":
      return "Français"
    case "hu":
      return "Magyar"
    case "id":
      return "Bahasa Indonesia"
    case "it":
      return "Italiano"
    case "pl":
      return "Polski"
    case "pt":
      return "Português"
    case "pt-BR":
      return "Português"
    case "ro":
      return "Română"
    case "ru":
      return "Pусский"
    case "tr":
      return "Türkçe"
    case "vi":
      return "Tiếng Việt"
    case "zh-TW":
      return "繁體中文"
    default:
      return ""
  }
}

export const regionWithDefaultLang = (region: string) => {
  switch (region) {
    case "br":
      return "pt-BR"
    case "tr":
      return "tr"
    case "fr":
      return "fr"
    case "kr":
      return "ko"
    default:
      return "en"
  }
}

export const convertNumericCharacterToString = (str: string) => {
  return str.replace(/&#(\d+);/g, (_, dec) => {
    return String.fromCharCode(dec)
  })
}

export const ConvertUTCToDisplayDate = (date: string) => {
  const dateO = new Date(date)

  // Define the month names
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ]

  // Extract the day, month, and year from the date object
  const day = dateO.getDate()
  const month = monthNames[dateO.getMonth()]
  const year = dateO.getFullYear()

  // Construct the formatted date string
  const formattedDate = `${month} ${day}, ${year}`

  return formattedDate
}

export const validateEmail = (email: string) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return pattern.test(email) && email.length <= 300
}
