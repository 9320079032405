import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on BR site, only show pt-BR locale, on all other sites show pt
export const usePreserveItalian = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "it") {
      if (
        (currentLanguage !== "en" || cookies["locale"] !== "en") &&
        (currentLanguage !== "it" || cookies["locale"] !== "it")
      ) {
        setTimeout(() => {
          i18n.changeLanguage("it")
        })
      }

      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("it")
        })
      }
    }
  }, [region])
}
