import React from "react"
import {
  Global,
  css,
  ThemeProvider as EmotionThemeProvider,
  useTheme,
} from "@emotion/react"
import { setConfiguration } from "react-grid-system"
import { gridWidths } from "@/utils/media-queries"
import nextTheme from "./next-theme"

// Set configuration for react grid system
// containder max widths
setConfiguration({
  ...gridWidths,
  maxScreenClass: "lg",
})

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <EmotionThemeProvider theme={nextTheme}>{children}</EmotionThemeProvider>
  )
}

export const FixedGlobalStyle = () => {
  return (
    <Global
      styles={css`
        html,
        body,
        h1,
        h2,
        h3,
        p,
        span,
        input,
        textarea,
        button {
          font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI,
            Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
            Segoe UI Emoji, Segoe UI Symbol;
          font-style: normal;
        }

        html,
        body {
          margin: 0;
          padding: 0;
        }

        * {
          box-sizing: border-box;
        }
      `}
    />
  )
}

export const ThemeGlobalStyle = () => {
  const theme = useTheme()
  return (
    <Global
      styles={css`
        html,
        main {
          color: ${theme.colors.mainText};
          background-color: ${theme.colors.backgroundDark};
        }
      `}
    />
  )
}
