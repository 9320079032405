import { useGeoCountry as useNavGeoCountry } from "@cdc-internal/mkt-nav-footer"
import React, { createContext, useContext } from "react"

export const DEFAULT_GEO_COUNTRY = "GLOBAL"

export type GeoCountryContextProps = {
  isLoading: boolean
  geoCountry: string
}

export const GeoCountryContext = createContext<GeoCountryContextProps>({
  isLoading: true,
  geoCountry: DEFAULT_GEO_COUNTRY,
})

export const GeoCountryProvider: React.FC<{ children: React.ReactNode }> =
  props => {
    const { geoCountry, isLoading } = useNavGeoCountry()

    return (
      <GeoCountryContext.Provider {...props} value={{ geoCountry, isLoading }}>
        {props.children}
      </GeoCountryContext.Provider>
    )
  }

export const useGeoCountry = (): GeoCountryContextProps => {
  return useContext(GeoCountryContext)
}
