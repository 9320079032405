import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on BR site, only show pt-BR locale, on all other sites show pt
export const usePreservePortuguese = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "br") {
      if (currentLanguage === "pt" || cookies["locale"] === "pt") {
        setTimeout(() => {
          i18n.changeLanguage("pt-BR")
        })

        setCookie("locale", "pt-BR", {
          path: "/",
        })
      }
      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("pt-BR")
        })
      }
    } else {
      if (currentLanguage === "pt-BR" || cookies["locale"] === "pt-BR") {
        setTimeout(() => {
          i18n.changeLanguage("pt")
        })
        setCookie("locale", "pt", {
          path: "/",
        })
      }
    }
  }, [region])
}
