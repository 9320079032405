import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on TR site, only English is preserved
export const usePreserveTurkish = (region: LowerRegions) => {
  const [cookies] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "tr") {
      if (
        (currentLanguage !== "en" || cookies["locale"] !== "en") &&
        (currentLanguage !== "tr" || cookies["locale"] !== "tr")
      ) {
        setTimeout(() => {
          i18n.changeLanguage("tr")
        })
      }
    }
  }, [region])
}
