import Defi from "@/images/tokens/icon/defi.svg"
import Gaming from "@/images/tokens/icon/gaming.svg"
import Online from "@/images/tokens/icon/online.svg"
import Trading from "@/images/tokens/icon/trading.svg"
import Transfer from "@/images/tokens/icon/transfer.svg"
import Donation from "@/images/tokens/icon/donation.svg"
import Hedging from "@/images/tokens/icon/hedging.svg"
import Microtransactions from "@/images/tokens/icon/microt.svg"
import { graphql, useStaticQuery } from "gatsby"
import { keyBy } from "lodash-es"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export const imageQuery = graphql`
  query bitcoinImages {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "tokens/bitcoin" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH)
          }
          name
        }
      }
    }
  }
`

export const useData = () => {
  const fileData = keyBy(
    useStaticQuery<Queries.bitcoinImagesQuery>(imageQuery).allFile.edges,
    i => i.node.name
  )

  return {
    heroImage: fileData["hero"]?.node?.childImageSharp?.gatsbyImageData,
    slug: "bitcoin",
    name: "Bitcoin",
    symbol: "BTC",
    icon: (
      <StaticImage
        src="https://static.crypto.com/token/icons/bitcoin/color_icon.png"
        alt="Bitcoin"
      />
    ),
    introduction:
      "In the ever-evolving landscape of cryptocurrency, one name stands out as the pioneer that sparked a revolution: <a href='https://crypto.com/university/what-is-bitcoin?utm_source=crypto.com+university&utm_medium=referral&utm_campaign=what+is+blockchain&utm_content=bitcoin' target='blank' >Bitcoin</a>. Since its inception in 2008 by an individual or group using the pseudonym <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a>, Bitcoin has grown to become the world's foremost digital currency, celebrated for its autonomy, security, and potential as a store of value.<br /><br />Often referred to as 'digital gold’, Bitcoin has captivated the financial world. In this comprehensive beginner's guide, we delve into its fundamentals.",
    universities: [
      {
        cover: fileData["university1"]?.node?.childImageSharp?.gatsbyImageData,
        level: "BEGINNER'S GUIDE TO BITCOIN",
        title: "What Is Bitcoin and How Does It Work?",
        summary:
          "Bitcoin is the world’s most widely accepted cryptocurrency, allowing for secure and seamless peer-to-peer transactions.",
        link: "https://crypto.com/bitcoin/what-is-bitcoin",
        alt: "Man standing in front of illustrated token with BTC icon",
      },
      {
        cover: fileData["university2"]?.node?.childImageSharp?.gatsbyImageData,
        level: "BEGINNER",
        title: "How to Buy, Store, and Send Bitcoin",
        summary:
          "From secure Bitcoin wallets to how the process works to sending and receiving BTC — this article explains the how-tos of Bitcoin.",
        link: "https://crypto.com/university/how-to-buy-bitcoin",
        alt: "BTC mining process with cards turning from black to transparent",
      },
    ],
    whatHow: [
      {
        cover: fileData["ch1"]?.node?.childImageSharp?.gatsbyImageData,
        title: "What Is Bitcoin Halving?",
        summary:
          "About every four years, the amount of Bitcoin rewards is halved. Here is how this has affected Bitcoin price.",
        link: "https://crypto.com/bitcoin/what-is-bitcoin-halving",
        alt: "Bitcoin halving process with light slashing token with BTC image",
      },
      {
        cover:
          fileData["how-to-use-btc"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How to Use Bitcoin (BTC)",
        summary:
          "Popular ways to use Bitcoin and what traders can buy with it.",
        link: "https://crypto.com/bitcoin/how-to-use-bitcoin",
        alt: "How to Use Bitcoin (BTC)",
      },
      {
        cover: fileData["ch4"]?.node?.childImageSharp?.gatsbyImageData,
        title: "What Is Bitcoin Trading?",
        summary: "Learn the basics of Bitcoin trading and how to get started.",
        link: "https://crypto.com/bitcoin/bitcoin-trading",
        alt: "What Is Bitcoin Trading?",
      },
      {
        cover:
          fileData["how-to-send-btc"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How to Send Bitcoin",
        summary:
          "Sending Bitcoin is easy and takes only a couple of minutes. Learn how to send Bitcoin on Crypto.com and start using it to do more.",
        link: "https://crypto.com/bitcoin/how-to-send-bitcoin",
        alt: "How to Send Bitcoin",
      },
      {
        cover: fileData["short"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How to Short Bitcoin",
        summary:
          "Learn about how to short Bitcoin to potentially profit even when prices decline.",
        link: "https://crypto.com/bitcoin/how-to-short-bitcoin",
        alt: "How to Short Bitcoin",
      },
      {
        cover: fileData["btc-created"]?.node?.childImageSharp?.gatsbyImageData,
        title: "Why Was Bitcoin Created?",
        summary:
          "Learn about the creation of Bitcoin, the world’s first cryptocurrency.",
        link: "https://crypto.com/bitcoin/why-was-bitcoin-created",
        alt: "Why Was Bitcoin Created?",
      },
      {
        cover: fileData["btc-valuable"]?.node?.childImageSharp?.gatsbyImageData,
        title: "Why Is Bitcoin Valuable?",
        summary:
          "Ever wondered why Bitcoin is valuable? Here are the key factors that influence BTC’s price and market value.",
        link: "https://crypto.com/bitcoin/bitcoin-value-what-drives-btc-worth",
        alt: "Why Is Bitcoin Valuable? Understand the Key Factors That Drive BTC’s Worth",
      },
      {
        cover: fileData["btc-node"]?.node?.childImageSharp?.gatsbyImageData,
        title: "What Is a Bitcoin Node? ",
        summary:
          "Uncovering the importance of nodes in making Bitcoin the future of money.",
        link: "https://crypto.com/bitcoin/what-is-a-bitcoin-node",
        alt: "What Is a Bitcoin Node? ",
      },
      {
        cover: fileData["btc-who"]?.node?.childImageSharp?.gatsbyImageData,
        title: "Who Created Bitcoin?",
        summary:
          "Learn what we know about the identity of the anonymous founder of Bitcoin, Satoshi Nakamoto, and the potential reasons why they decided to remain undoxxed.",
        link: "https://crypto.com/bitcoin/who-created-bitcoin",
        alt: "Who Created Bitcoin?",
      },
      {
        cover: fileData["btc-many"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How Many Bitcoins Are There in Total?",
        summary:
          "Ever wonder how many bitcoins there are in total and how many can still be mined? Find answers to Bitcoin numbers in this article.",
        link: "https://crypto.com/bitcoin/how-many-bitcoins-are-there",
        alt: "How Many Bitcoins Are There in Total?",
      },
      {
        cover: fileData["btc-energy"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How Much Energy Does Bitcoin Consume?",
        summary:
          "Here’s how energy-intensive Bitcoin’s Proof of Work consensus mechanism really is and what’s being done to offset it.",
        link: "https://crypto.com/bitcoin/bitcoin-energy-consumption",
        alt: "How Much Energy Does Bitcoin Consume?",
      },
      {
        cover: fileData["btc-own"]?.node?.childImageSharp?.gatsbyImageData,
        title: "Who Owns the Most Bitcoin?",
        summary:
          "Individuals and corporations make up the list of who owns the most Bitcoin. See who they currently are.",
        link: "https://crypto.com/bitcoin/who-owns-the-most-bitcoin",
        alt: "Who Owns the Most Bitcoin?",
      },
      {
        cover: fileData["BTC-vs-ETH-1"]?.node?.childImageSharp?.gatsbyImageData,
        title:
          "Bitcoin vs Ethereum: What’s the Difference Between BTC and ETH?",
        summary:
          "The two biggest cryptocurrencies are very different. Learn what makes BTC and ETH unique.",
        link: "https://crypto.com/bitcoin/bitcoin-vs-ethereum-whats-the-difference-between-btc-and-eth",
        alt: "Bitcoin vs Ethereum: What’s the Difference Between BTC and ETH?",
      },
      {
        cover:
          fileData["how-is-btc-taxed"]?.node?.childImageSharp?.gatsbyImageData,
        title: "How Is Bitcoin Taxed? A Complete Guide for 2024",
        summary:
          "How is Bitcoin taxed? Learn everything from how various jurisdictions tax Bitcoin to key tax events and best practices for users.",
        link: "https://crypto.com/bitcoin/how-is-bitcoin-taxed-a-complete-guide-for-2024",
        alt: "How Is Bitcoin Taxed? A Complete Guide for 2024",
      },
      {
        cover:
          fileData["who-accept-btc"]?.node?.childImageSharp?.gatsbyImageData,
        title: "Who Accepts Bitcoin Payments in 2024?",
        summary:
          "A look into some of the brands and retailers that accept Bitcoin — and how to still buy with BTC when a business doesn’t accept cryptocurrency.",
        link: "https://crypto.com/bitcoin/who-accepts-bitcoin-payments-in-2024",
        alt: "Who Accepts Bitcoin Payments in 2024?",
      },
    ],
    buyStep: {
      title: "Buy Bitcoin in <highlight>3 Steps</highlight>",
      titleMb:
        "Buy Bitcoin<br/><textSm>in <highlight>3 Steps</highlight></textSm>",
      content: [
        {
          img: fileData["step1"]?.node?.childImageSharp?.gatsbyImageData,
          title: "Download our app",
          description:
            "Scan the QR code above to download our app. Available on the App Store and Google Play.",
          alt: "Phone screen with QR code to download Crypto.com app",
        },
        {
          img: fileData["step2"]?.node?.childImageSharp?.gatsbyImageData,
          title: "Fund your wallet",
          description:
            "Once verified, you can deposit USD via bank transfer or credit card to your FIAT wallet",
          alt: "Phone screen illustrating how to fund your wallet on Crypto.com app",
        },
        {
          img: fileData["step3"]?.node?.childImageSharp?.gatsbyImageData,
          title: "Buy Bitcoin",
          description:
            "Verified and funded, you are now all set for your first Bitcoin purchase!",
          alt: "Phone frame illustrating how to buy BTC on Crypto.com app",
        },
      ],
    },
    whatIs: {
      title: "What Is Bitcoin?",
      content:
        "<ul><li>Bitcoin (<a href='https://crypto.com/price/bitcoin' target='__blank'>BTC</a>) is a peer-to-peer (P2P) digital currency system conceived in 2008 by an anonymous person or group with the moniker <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a>.</li><li>Using the Proof of Work (<a href='https://crypto.com/university/proof-of-stake-vs-proof-of-work' target='__blank'>PoW</a>) consensus mechanism, the network rewards miners for maintaining the system.</li><li>Bitcoin is the <a href='https://crypto.com/bitcoin/why-was-bitcoin-created' target='__blank'>first fully autonomous and self-sustaining payment network</a>, where no single party can intervene in or terminate it.</li><li>Since the inception of Bitcoin, hundreds of thousands of <a href='https://crypto.com/university/what-are-altcoins' target='__blank'>altcoins</a> have been created, each offering its own use cases and benefits.</li><li>Bitcoin is also referred to as ‘digital gold’ by some, as only a finite supply of Bitcoin exists and can be mined.</li></ul>",
      footer:
        "Learn more about the world’s biggest cryptocurrency in this <a href='https://crypto.com/bitcoin/what-is-bitcoin' target='__blank'>beginner’s guide to Bitcoin.</a>",
    },
    quote: [
      {
        content:
          "“Every informed person needs to know about Bitcoin because it might be one of the world’s most important developments.”",
        reference: "<highlight>— Leon Luow<highlight>",
      },
      {
        headline: "Mining",
        content:
          "Bitcoin mining is the process of using powerful computers to solve complex mathematical puzzles, validating and bundling transactions into blocks, and adding them to the public ledger called the blockchain.",
        reference:
          "Learn more about <a href='https://crypto.com/university/bitcoin-mining' target='__blank'>Bitcoin Mining</a>",
      },
    ],
    moreOns: [
      {
        cover: fileData["ch1"]?.node?.childImageSharp?.gatsbyImageData,
        title: "What Is Bitcoin Halving?",
        level: "BEGINNER",
        link: "https://crypto.com/bitcoin/what-is-bitcoin-halving",
        alt: "Bitcoin halving process with light slashing token with BTC image",
      },
      {
        cover:
          fileData["what-is-bitcoin-worth"]?.node?.childImageSharp
            ?.gatsbyImageData,
        title: "What Is Bitcoin Worth?",
        level: "BEGINNER",
        link: "https://crypto.com/university/bitcoin-store-of-value-comparison-to-gold",
        alt: "Hand holding two gold blocks representing Bitcoin's value",
      },
      {
        cover: fileData["ch4"]?.node?.childImageSharp?.gatsbyImageData,
        title: "What Is Bitcoin Trading?",
        level: "BEGINNER",
        link: "https://crypto.com/bitcoin/bitcoin-trading",
        alt: "Trading Bitcoin with two hands exchanging token with BTC icon",
      },
    ],
    howUseTokens: [
      {
        icon: Online,
        title: "Online Purchases",
        content:
          "<ul><li>Some online retailers and businesses accept <a href='https://crypto.com/university/how-to-pay-with-crypto' target='__blank'>Bitcoin as a form of payment</a> for goods and services.</li><li>You can <a href='https://crypto.com/bitcoin/how-to-use-bitcoin' target='__blank'>use Bitcoin</a> to buy products ranging from electronics to gift cards and even travel services.</li></ul>",
      },
      {
        icon: Trading,
        title: "Trading",
        content:
          "<ul><li>Many people view Bitcoin as a speculative asset, aiming to benefit from price fluctuations.</li><li>You can buy and hold Bitcoin long-term or engage in short-term trading on cryptocurrency exchanges.</li></ul>",
      },
      {
        icon: Transfer,
        title: "International Transfers",
        content:
          "<ul><li>Bitcoin enables cost-effective and faster cross-border money transfers compared to traditional financial systems.</li><li>This can be especially useful for sending money to family members or friends in different countries.</li></ul>",
      },
      {
        icon: Donation,
        title: "Donations and Fundraising",
        content:
          "<ul><li>Some charitable organisations and nonprofit entities accept Bitcoin donations.</li><li>Cryptocurrency can streamline the donation process and offer transparency in how funds are used.</li></ul>",
      },
      {
        icon: Hedging,
        title: "Hedging and Store of Value",
        content:
          "<ul><li>Some traders <a href='https://crypto.com/bitcoin/how-to-use-bitcoin' target='__blank'>use Bitcoin</a> as a hedge against traditional financial assets like stocks and bonds.</li><li>Bitcoin's limited supply and decentralised nature have led some to consider it a digital store of value, similar to gold.</li></ul>",
      },
      {
        icon: Gaming,
        title: "Gaming and Digital Goods",
        content:
          "<ul><li>Some <a href='https://crypto.com/university/what-is-gamefi-play-to-earn-gaming-explained' target='__blank'>gaming</a> platforms and online marketplaces accept Bitcoin for purchasing in-game items, digital goods, and services.</li>",
      },
      {
        icon: Microtransactions,
        title: "Microtransactions and Tips",
        content:
          "<ul><li>Some charitable organisations and nonprofit entities accept Bitcoin donations.</li><li>Cryptocurrency can streamline the donation process and offer transparency in how funds are used.</li></ul>",
      },
      {
        icon: Defi,
        title:
          "<a href='https://crypto.com/university/how-to-use-defi-beginners-guide' target='__blank'>DeFi</a> (Decentralised Finance)",
        content:
          "<ul><li>Bitcoin's scripting capabilities enable simple smart contracts, though it's less advanced compared to some other cryptocurrencies.</li><li>In the wider blockchain ecosystem, various projects offer decentralised financial services like lending, borrowing, and <a href='https://crypto.com/university/earn-defi-wallet' target='__blank'>yield farming</a> using Bitcoin.</li></ul>",
      },
    ],
    QandA: [
      {
        question: "What Is Bitcoin?",
        content: [
          {
            type: "text",
            value:
              "<ul><li>Bitcoin (<a href='https://crypto.com/price/bitcoin' target='__blank'>BTC</a>) is a peer-to-peer (P2P) digital currency system conceived in 2008 by an anonymous person or group with the moniker <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a>.</li><li>Using the Proof of Work (<a href='https://crypto.com/university/proof-of-stake-vs-proof-of-work' target='__blank'>PoW</a>) consensus mechanism, the network rewards miners for maintaining the system.</li><li>Bitcoin is the <a href='https://crypto.com/bitcoin/why-was-bitcoin-created' target='__blank'>first fully autonomous and self-sustaining payment network</a>, where no single party can intervene in or terminate it.</li><li>Since the inception of Bitcoin, hundreds of thousands of <a href='https://crypto.com/university/what-are-altcoins' target='__blank'>altcoins</a> have been created, each offering its own use cases and benefits.</li><li>Bitcoin is also referred to as ‘digital gold’ by some, as only a finite supply of Bitcoin exists and can be mined.</li></ul>",
          },
          {
            type: "footer",
            value:
              "Learn more about the world’s biggest cryptocurrency in this <a href='https://crypto.com/bitcoin/what-is-bitcoin' target='__blank'>beginner’s guide to Bitcoin.</a>",
          },
        ],
      },
      {
        question: "Who Created Bitcoin?",
        content: [
          {
            type: "text",
            value:
              "<a href='https://crypto.com/bitcoin/who-created-bitcoin' target='__blank'>Bitcoin was created by</a> an individual or group of individuals using the pseudonym <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a>. The true identity of <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a> remains unknown; there has been much speculation and investigation over the years, but no conclusive evidence has surfaced regarding Nakamoto’s true identity.",
          },
        ],
      },
      {
        question: "The Bitcoin White Paper",
        content: [
          {
            type: "text",
            value:
              "Nakamoto published the Bitcoin white paper, titled ‘Bitcoin: A Peer-to-Peer Electronic Cash System’, in 2008, and released the first version of the Bitcoin software in 2009. Work continued on the project, communicating with the early Bitcoin community, before Nakamoto eventually disappeared from public view in 2010.",
          },
          {
            type: "footer",
            value:
              "Read the <a href='https://bitcoin.org/bitcoin.pdf' target='__blank'>Bitcoin white paper here</a>, written by <a href='https://crypto.com/glossary/satoshi-nakamoto' target='__blank'>Satoshi Nakamoto</a>.",
          },
        ],
      },
      {
        question: "How Does Bitcoin Work?",
        content: [
          {
            type: "text",
            value:
              "Bitcoin uses <a href='https://crypto.com/university/what-is-blockchain-consensus' target='__blank'>blockchain</a> technology, and one of blockchain’s main features is the way it records data, which is:<br /><ul><li>Immutable</li><li>Transparent</li><li>Decentralised</li></ul>Immutable means that no entity can modify the transaction records. Transparent implies that anyone can see and verify the transactions on a blockchain via the internet. Decentralised means it is very difficult for a single entity to govern the whole network (although <a href='https://crypto.com/glossary/51-attack' target='__blank'>51% attacks</a>, where a group obtains more than half of <a href='https://crypto.com/university/what-is-crypto-mining' target='__blank'>mining</a> power, are theoretically possible).<br /><br />Bitcoin was the first application to utilise blockchain technology (even though the term ‘blockchain’ was introduced after Bitcoin). There are two main stakeholders in the Bitcoin system: regular users, who can access the system to make transactions, and <a href='https://crypto.com/university/bitcoin-mining' target='__blank'>miners</a>, who work behind the scenes to power and maintain the network.",
          },
          {
            type: "image",
            value:
              fileData["how-bitcoin-work"]?.node?.childImageSharp
                ?.gatsbyImageData,
            alt: "Infographic explaining Bitcoin's transaction process from sender to validation to receivers",
          },
          {
            type: "text",
            value:
              "Miners run computers, called ‘<a href='https://crypto.com/bitcoin/what-is-a-bitcoin-node' target='__blank'>nodes</a>’, with <a href='https://crypto.com/bitcoin/bitcoin-energy-consumption' target='__blank'>a lot of processing power</a> in order to store data, handle and broadcast transactions, and solve a complex mathematical puzzle in order to mine new ‘blocks’ on the blockchain, which contain recent transactions. Every time someone sends a transaction to the Bitcoin network, miners pick up the transaction and complete the steps above behind the scenes.<br /><br />Miners do not volunteer to maintain this decentralised network for free, however. They are incentivised to do so by receiving mining rewards and transaction fees upon completing the equation and validating transactions. Their reward for creating new blocks comes in the form of newly minted Bitcoin, which is why the process is called mining.",
          },
          {
            type: "footer",
            value:
              "To gain a deeper understanding of the technological aspects of Bitcoin, <a href='https://crypto.com/bitcoin/what-is-bitcoin' target='__blank'>check out this introduction to how Bitcoin works.</a>",
          },
        ],
      },
      {
        question: "What Is Bitcoin Worth?",
        content: [
          {
            type: "text",
            value: "The current price of Bitcoin in US$ is:",
          },
          {
            type: "priceWidget",
            symbol: "BTC",
            icon: (
              <StaticImage
                src="https://static.crypto.com/token/icons/bitcoin/color_icon.png"
                alt="Bitcoin"
              />
            ),
            slug: "bitcoin",
          },
          {
            type: "text",
            value:
              "However, BTC has experienced highs and lows since it launched in 2009. Bitcoin hit the US$100 mark in 2013, four years after going public. In 2021, BTC surged to its current all-time high (ATH) price of over US$68,789. Since then, it has mainly traded above US$20,000. Its most recent low was in November 2022, when it traded around US$17,600 for most of the month.",
          },
          {
            type: "image",
            value:
              fileData["bitcoin-price-chart"]?.node?.childImageSharp
                ?.gatsbyImageData,
            alt: "Bitcoin price chart from 2011 to 2023",
          },
          {
            type: "footer",
            value:
              "Learn more about Bitcoin price, <a href='https://crypto.com/bitcoin/bitcoin-value-what-drives-btc-worth' target='__blank'>its value</a>, and recent price trends on the <a href='https://crypto.com/price/bitcoin' target='__blank'>Bitcoin Price page.</a>",
          },
        ],
      },
      {
        question: "How to Trade Bitcoin",
        content: [
          {
            type: "text",
            value:
              "Users can purchase Bitcoin in the <a href='https://crypto.com/app' target='__blank'>Crypto.com App</a> and on the <a href='https://crypto.com/exchange' target='__blank'>Crypto.com Exchange</a>. Payment options include bank transfer, credit/debit cards, and other cryptocurrencies. Once bought, users can store their newly acquired bitcoins in the Crypto.com App that doubles as a <a href='https://crypto.com/university/crypto-wallets' target='__blank'>crypto wallet</a>, or other software and hardware crypto wallets.",
          },
          {
            type: "footer",
            value:
              "Learn more about buying Bitcoin <a href='https://crypto.com/university/how-to-buy-bitcoin' target='__blank'>here.</a>",
          },
        ],
      },
      {
        question: "What Is the Safest Way to Store Bitcoin?",
        content: [
          {
            type: "image",
            value:
              fileData["key-image"]?.node?.childImageSharp?.gatsbyImageData,
            alt: "Image illustrating cold wallets for storing cryptocurrency",
          },
          {
            type: "text",
            value:
              "Bitcoin is always recorded on the Bitcoin blockchain, but <a href='https://crypto.com/bitcoin/who-owns-the-most-bitcoin' target='__blank'>ownership</a> of bitcoins is determined via a private key, which is usually stored in a Bitcoin wallet. Wallets are software programmes or hardware devices that allow users to securely store, send, and receive bitcoins.<br /><br />There are several types of wallets available, each with its own level of security and convenience. These include hot wallets, which are apps connected to the internet, and <a href='https://crypto.com/university/what-is-a-hardware-wallet' target='__blank'>cold wallets</a>, which are usually hardware devices that securely store BTC offline.",
          },
          {
            type: "footer",
            value:
              "Read more about the different types of crypto wallets <a href='https://crypto.com/university/crypto-wallets' target='__blank'>here.</a>",
          },
        ],
      },
      {
        question: "Sending Bitcoin",
        content: [
          {
            type: "image",
            value:
              fileData["crypto-com-cube"]?.node?.childImageSharp
                ?.gatsbyImageData,
            alt: "Image illustrating how to store cryptocurrency securely",
          },
          {
            type: "text",
            value:
              "To <a href='https://crypto.com/bitcoin/how-to-send-bitcoin' target='__blank'>send Bitcoin</a>, you need a Bitcoin wallet and the recipient's Bitcoin address. Here are the steps to <a href='https://crypto.com/bitcoin/how-to-send-bitcoin' target='__blank'>send Bitcoin</a>:",
          },
          {
            type: "text",
            value:
              "<ul><li>Log into your Bitcoin wallet.</li><br /><li>In your wallet, navigate to the ‘Send’ option.</li><br /><li>Enter the recipient's Bitcoin address. It is a long string of alphanumeric characters, typically starting with a '1' or '3'.</li><br /><li>Enter the amount of Bitcoin you want to send. Be mindful of transaction fees, which vary depending on network congestion and wallet settings.</li><br /><li>Once the details are correct, click the 'Send' button to initiate the transaction.</li><br /><li>Bitcoin transactions require confirmation from the network. Depending on network congestion, this process may take a few minutes to several hours.</li><br /><li>Once the transaction is confirmed, the recipient will receive the Bitcoin in their wallet.</li></ul>",
          },
        ],
      },
      {
        question: "Bitcoin Terms to Know",
        content: [
          {
            type: "subTopic",
            title: "Bitcoin Mining",
            content: [
              {
                type: "string",
                value:
                  "Bitcoin mining is the process of using powerful computers to solve complex mathematical puzzles, validating and bundling transactions into blocks, and adding them to the public ledger, called the blockchain.",
              },
            ],
            learnMore:
              "Learn more about how Bitcoin mining works <a href='https://crypto.com/university/bitcoin-mining' target='__blank'>in this article.</a>",
          },
          {
            type: "subTopic",
            title: "Bitcoin Halving",
            content: [
              {
                type: "html",
                value:
                  "Bitcoin is programmed to <a href='https://crypto.com/bitcoin/how-many-bitcoins-are-there' target='__blank' style='font-weight: 600;'>a total maximum supply of 21 million BTC</a>, and about every four years, the amount of Bitcoin rewards for mining is halved. This periodic decrease in the rate of bitcoins issued into circulation is called ‘Bitcoin halving’ and can push BTC price up.",
              },
              {
                type: "image",
                src: fileData["bar-chart"]?.node?.childImageSharp
                  ?.gatsbyImageData,
                alt: "Infographic showing the number and trend of BTC halving events",
              },
              {
                type: "string",
                value:
                  "In 2012, the reward was 25 bitcoins per block, and in 2016, it decreased to 12.5 bitcoins per block. As of early 2023, miners are rewarded 6.25 bitcoins per block mined.",
              },
              {
                type: "image",
                src: fileData["line-chart"]?.node?.childImageSharp
                  ?.gatsbyImageData,
                alt: "Infographic projecting the future trend of BTC halving events",
              },
            ],
            learnMore:
              "The next Bitcoin halving is estimated for 2024. Learn more about Bitcoin halving <a href='https://crypto.com/bitcoin/what-is-bitcoin-halving' target='__blank'>in this article</a>.",
          },
          {
            type: "subTopic",
            title: "Bitcoin Dominance",
            content: [
              {
                type: "string",
                value:
                  "Bitcoin dominance is the ratio of Bitcoin’s market capitalisation to the overall market cap of all cryptocurrencies. It serves as a metric used by crypto traders in trying to get a pulse of the market, spot possible trends and trading opportunities, and understand trends in the larger crypto market.In 2012, the reward was 25 bitcoins per block, and in 2016, it decreased to 12.5 bitcoins per block. As of early 2023, miners are rewarded 6.25 bitcoins per block mined.",
              },
              {
                type: "image",
                src: fileData["bitcoin-dominance"]?.node?.childImageSharp
                  ?.gatsbyImageData,
                alt: "Bitcoin dominance image",
              },
            ],
            learnMore:
              "More about <a href='https://crypto.com/university/what-is-bitcoin-dominance' target='__blank'>Bitcoin Dominance.</a>",
          },
        ],
      },
      {
        question: "Trading Bitcoin",
        content: [
          {
            type: "text",
            value:
              "In a nutshell, Bitcoin trading involves monitoring the market for price fluctuations and executing buy or sell orders accordingly. More advanced trading options are offered beyond simple buy and sell orders; for example, long and <a href='https://crypto.com/bitcoin/how-to-short-bitcoin' target='__blank'>short positions</a>, and derivatives (like futures and options).",
          },
          {
            type: "text",
            value:
              "To learn more about Bitcoin trading options, check out this <a href='https://crypto.com/bitcoin/bitcoin-trading' target='__blank'>article</a>.",
          },
          {
            type: "text",
            value:
              "<subtitle>Due Diligence and Do Your Own Research</subtitle>All examples listed in this article are for informational purposes only. You should not construe any such information or other material as legal, tax, investment, financial, or other advice. Nothing contained herein shall constitute a solicitation, recommendation, endorsement, or offer by <a href='https://crypto.com/' target='__blank'>Crypto.com</a> to invest, buy or sell any digital assets. Returns on the buying and selling of digital assets may be subject to tax, including capital gains tax and/or income tax, in your jurisdiction or the jurisdictions in which you are resident for tax purposes. Any descriptions of Crypto.com products or features are merely for illustrative purposes and do not constitute an endorsement, invitation, or solicitation.<br /><br />In addition, the Crypto.com Exchange and the products described herein are distinct from the Crypto.com Main App, and the availability of products and services on the Crypto.com Exchange is subject to jurisdictional limits. Before accessing the Crypto.com Exchange, please refer to the following links and ensure that you are not in any geo-restricted jurisdictions for <a href='https://help.crypto.com/en/articles/6320975-spot-trading-geo-restrictions' target='__blank'>Spot Trading</a> and <a href='https://help.crypto.com/en/articles/4475382-margin-trading-geo-restrictions' target='__blank'>Margin Trading</a>. <br /><br />Past performance is not a guarantee or predictor of future performance. The value of digital assets can go down as well as up and you could lose all or a substantial amount of your purchase price. When assessing a digital asset, it’s essential for you to do your own research and due diligence to make the best possible judgement, as any purchases shall be your sole responsibility.",
          },
        ],
      },
    ],
  }
}

export type Data = ReturnType<typeof useData>
