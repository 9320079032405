import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"
import qs from "qs"

interface OneLinkContextType {
  queryString: string
}

const OneLinkContext = createContext<{ queryString: string }>({
  queryString: "",
})

export const OneLinkProvider: FC<{ children: React.ReactNode }> = props => {
  const [queryString, setQueryString] = useState("")
  useEffect(() => {
    setQueryString(generatequeryString())
  }, [])

  function generatequeryString() {
    const queryStringObject = qs.parse(window.location.search.replace("?", ""))
    const queryMapping: { [key: string]: string } = {
      utm_source: "pid",
      utm_campaign: "c",
      gclid: "af_sub2",
      utm_channel: "af_channel",
      utm_ad: "af_ad",
    }

    const newQuery: { [key: string]: any } = {}
    for (const [key, value] of Object.entries(queryStringObject)) {
      if (!queryMapping[key]) continue
      newQuery[queryMapping[key]] = value
    }

    if (newQuery == null || Object.keys(newQuery).length === 0) {
      return ""
    }

    newQuery["af_js_web"] = true
    return "?" + qs.stringify(newQuery)
  }

  return (
    <OneLinkContext.Provider {...props} value={{ queryString }}>
      {props.children}
    </OneLinkContext.Provider>
  )
}

export const useOneLink = (): OneLinkContextType => {
  return useContext(OneLinkContext)
}
