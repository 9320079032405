import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on BR site, only show pt-BR locale, on all other sites show pt
export const usePreserveKorean = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "kr") {
      if (
        (currentLanguage !== "en" || cookies["locale"] !== "en") &&
        (currentLanguage !== "ko" || cookies["locale"] !== "ko")
      ) {
        setTimeout(() => {
          i18n.changeLanguage("ko")
        })
      }

      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("ko")
        })
      }
    } else {
      if (currentLanguage === "ko" || cookies["locale"] === "ko") {
        setTimeout(() => {
          i18n.changeLanguage("en")
        })
        setCookie("locale", "en", {
          path: "/",
        })
      }
    }
  }, [region])
}
