export const regionCookieName = "preferred-region"

/**
 * @see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 for country code
 * @see https://mcoproduct.atlassian.net/browse/WRP1-445 for country/region requirement
 * NOTE region and country are in a one-to-many relationship
 */

export const ukCountryList = ["GB", "GBR"]

export const eeaCountryList = [
  //"GB",
  //"GBR",
  "CH",
  "NO",
  "LI",
  "IS",
  "SE",
  "ES",
  "SI",
  "SK",
  "RO",
  "PT",
  "PL",
  "NL",
  "MT",
  "LU",
  "LT",
  "LV",
  //  "IT",
  "IE",
  "HU",
  "GR",
  "DE",
  //  "FR",
  "FI",
  "EE",
  "DK",
  "CZ",
  "CY",
  "HR",
  "BG",
  "BE",
  "AT",
]

//DEBUG
export type Regions =
  | "US"
  | "EEA"
  | "SG"
  | "BR"
  | "AU"
  | "GLOBAL"
  | "TR"
  | "FR"
  | "CA"
  | "IT"
  | "UK"
  | "KR"
  | "AE"

export const regions: Regions[] = [
  "US",
  "EEA",
  "SG",
  "BR",
  "AU",
  "TR",
  "FR",
  "CA",
  "IT",
  "UK",
  "KR",
  "AE",
  "GLOBAL",
]

export const US = "US"
export const EEA = "EEA"
export const SG = "SG"
export const BR = "BR"
export const AU = "AU"
export const TR = "TR"
export const FR = "FR"
export const CA = "CA"
export const IT = "IT"
export const UK = "UK"
export const KR = "KR"
export const AE = "AE"
export const GLOBAL = "GLOBAL"

/**
 * NOTE ❗️ region cookie is in upper letters, but region path is in lower case
 */

//NOTE lower case of regions, can be improved in future
export type LowerRegions =
  | "us"
  | "eea"
  | "sg"
  | "br"
  | "au"
  | "global"
  | "tr"
  | "fr"
  | "ca"
  | "it"
  | "uk"
  | "kr"
  | "ae"

export const lowerRegions: LowerRegions[] = [
  "us",
  "eea",
  "sg",
  "br",
  "au",
  "tr",
  "fr",
  "ca",
  "it",
  "uk",
  "kr",
  "ae",
  "global",
]
export type AbbrRegions =
  | "usa"
  | "eea"
  | "sgp"
  | "bra"
  | "aus"
  | "ww"
  | "tur"
  | "can"
  | "fra"
  | "ita"
  | "gbr"
  | "kor"
  | "uae"

export const abbrRegions: AbbrRegions[] = [
  "usa",
  "eea",
  "sgp",
  "bra",
  "aus",
  "tur",
  "can",
  "fra",
  "ita",
  "gbr",
  "kor",
  "uae",
  "ww",
]
