import React, { createContext, useContext } from "react"

export type PageRegionContextProps = {
  pageRegion: string
}

export const PageRegionContext = createContext<PageRegionContextProps>({
  pageRegion: "",
})

export const PageRegionProvider: React.FC<{
  initPageRegion: string
  children: React.ReactNode
}> = ({ initPageRegion, children }) => {
  return (
    <PageRegionContext.Provider value={{ pageRegion: initPageRegion }}>
      {children}
    </PageRegionContext.Provider>
  )
}

export const usePageRegion = (): PageRegionContextProps => {
  return useContext(PageRegionContext)
}
