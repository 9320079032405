import "@emotion/react"

const spaceKeys = [...Array(100).keys()]
const space = spaceKeys.map(i => i * 8)
spaceKeys.forEach(i => {
  space[-i] = -1 * i * 8
})
space[0.5] = 4
space[1.5] = 12
space["-0.5"] = -4
space["-1.5"] = -12

const breakpoints: any = ["768px", "1128px"]
breakpoints.tablet = breakpoints[0]
breakpoints.laptop = breakpoints[1]

const theme = {
  breakpoints,
  fonts: {
    heading: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
    ].join(", "),
    body: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
    ].join(", "),
  },
  colors: {
    white: "#FFFFFF",
    black: "#000000",
    mainText: "#F4F4F4",
    titleText: "#F4F4F4",
    highlightText: "#1199FA",
    hightlightTextLightBg: "#0058AA",
    tokenText: "#D1D0D1",
    contentText: "#7D7D7D",
    primaryBrightBlue: "#1199FA",
    primaryBrightBlueLightBg: "#0058AA",
    primaryDeepBlack: "#000000",
    primaryPaleWhite: "#F4F4F4",
    primaryPaleWhiteRgb: "rgb(244, 244, 244, 0.3)",
    secondaryDarkNavy: "#0E213B",
    secondaryDarkNavyAlpha: "#0E213BB3",
    secondaryDarkGrey: "#7D7D7D",
    secondaryLightGrey: "#D1D0D1",
    backgroundDark: "#061121",
    backgroundDarkBlue: "#010118",
    backgroundLight: "#F4F4F4",
    backgroundWhite: "#FFFFFF",
    backgroundDownloadAppDark: "#061B38",
    backgroundDownloadAppLight: "#FFFFFF",
    backgroundCard: "#0E223C",
    backgroundSecurityWhite: "#E7E6E6",
    backgroundSecurityGrey: "#16171C",
    primaryPartnerGrey: "#323C52",
    primaryPartnerBlue: "#00254F",
    secondaryPartnerBlue: "#002F61",
    statusGreen: "#20BCA4",
    statusRed: "#E64B60",
    secondaryDarkButtonHoverBg: "rgba(255, 255, 255, 0.1)",
    secondaryLightButtonHoverBg: "rgba(19, 53, 100, 0.1)",
    backgroundBlueGlow:
      "radial-gradient(50% 50% at 50% 50%, rgba(65, 148, 236, 0.24) 0%, rgba(65, 148, 236, 0) 100%)",
    // TODO: Confirm with designers about the naming
    moreBlueColor: "#061525",
    cardTableBorder: "#37435380",
  },
  // Multiple of 8
  space,
  sizes: {
    containerWidth: 1128,
    navBarHeight: 92,
    navBarHeightCollapsed: 72,
  },
}

export type NextTheme = typeof theme

export default theme
