import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on BR site, only show pt-BR locale, on all other sites show pt
export const usePreserveUSEnglish = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "us") {
      if (
        (currentLanguage === "en" && !cookies["locale"]) ||
        cookies["locale"] === "en"
      ) {
        setTimeout(() => {
          i18n.changeLanguage("en-US")
        })
        setCookie("locale", "en-US", {
          path: "/",
        })
      } else {
        const lang = cookies["locale"]
        setTimeout(() => {
          i18n.changeLanguage(lang)
        })
      }
    } else {
      if (currentLanguage === "en-US" || cookies["locale"] === "en-US") {
        setTimeout(() => {
          i18n.changeLanguage("en")
        })
        setCookie("locale", "en", {
          path: "/",
        })
      }
    }
  }, [region])
}
