import axios from "axios"
import {
  eeaCountryList,
  ukCountryList,
  Regions,
  US,
  GLOBAL,
  SG,
  EEA,
  BR,
  AU,
  TR,
  FR,
  CA,
  IT,
  UK,
  KR,
  AE,
  LowerRegions,
  lowerRegions,
} from "@/configs/region"
// import { fallback } from "@/i18n/config"
import { removeTrailingSlash } from "@/utils"
import { navigate } from "gatsby"

/**
 * @description to set an expiration date
 */
export const extendsExpireYears = (year: number): Date => {
  const milliSecondsInOneYear = 60 * 60 * 24 * 365 * 1000
  const expireDate = new Date(Date.now() + milliSecondsInOneYear * year)
  return expireDate
}

/**
 * @description to get user IP
 */
export const traceUser = () =>
  axios.get<string>("https://crypto.com/cdn-cgi/trace", {
    responseType: "text",
  })

/**
 * NOTE this function is specifically for parsing traceUser return value to a key value pair
 */
type PairObject = { [key: string]: string }
export const getTraceObject = (text: string): PairObject => {
  const lines = text.trim().split("\n")
  const accumulator: PairObject = {}

  return lines.reduce((acc, cur) => {
    const pair = cur.split("=")
    acc[pair[0]] = pair[1]
    return acc
  }, accumulator)
}

export const getRegion = (regionValue = ""): Regions => {
  if ([US, SG, BR, AU, TR, FR, CA, IT, KR, AE].includes(regionValue)) {
    return regionValue as Regions
  }

  if (ukCountryList.includes(regionValue)) {
    return UK
  }

  if (eeaCountryList.includes(regionValue)) {
    return EEA
  }

  return GLOBAL
}

export const getRegions = (
  userRegion: Regions,
  regionPath: string,
  customConfig: Record<string, any>
): [suggestRegion: LowerRegions[], allRegion: LowerRegions[]] => {
  let suggestRegion: LowerRegions[] = []
  let allRegion: LowerRegions[] = []

  // NOTE: user with SG region ip, cannot change region
  if (userRegion === SG) {
    suggestRegion = ["sg"]
    allRegion = []
    return [suggestRegion, allRegion]
  }

  // NOTE: user from EEA Spanish, cannot change region
  if (userRegion === EEA) {
    suggestRegion = ["eea"]
    allRegion = []
    return [suggestRegion, allRegion]
  }

  const currentUserRegion =
    lowerRegions.find(region => userRegion.toLowerCase() === region) || "global"

  const currentRegionPath =
    lowerRegions.find(region => regionPath === region) || "global"
  if (currentUserRegion === currentRegionPath) {
    suggestRegion.push(currentRegionPath)
  } else {
    suggestRegion.push(currentRegionPath, currentUserRegion)
  }

  allRegion = lowerRegions.filter(region => {
    if (region === "global" && regionPath === "") return false
    return regionPath !== region && userRegion.toLowerCase() !== region
  })

  return [suggestRegion, allRegion]
}

// export const getLanguages = (
//   currentLanguage: string,
//   languages: string[]
// ): [suggestLangs: string[], allLangs: string[]] => {
//   const suggestLangs = [currentLanguage]
//   const allLangs = languages.filter(
//     language => language !== (currentLanguage || fallback)
//   )

//   return [suggestLangs, allLangs]
// }

/**
 * @param prefixedPath path with specific regions, for now /us/* and /eea/*
 */
const removeRegionPrefix = (prefixedPath: string) => {
  const paths = prefixedPath.split("/")
  paths.splice(0, 2)

  return "/" + paths.join("/")
}

/**
 * @description if input is a global path, return it, otherwise remove the region prefix the return
 */
export const getGlobalPath = (path: string) => {
  if (
    path.match(/^\/us/) ||
    path.match(/^\/eea/) ||
    path.match(/^\/sg/) ||
    path.match(/^\/br/) ||
    path.match(/^\/au/) ||
    path.match(/^\/tr/) ||
    path.match(/^\/fr/) ||
    path.match(/^\/it/) ||
    path === "/ca" ||
    path === "/ca/" ||
    path.match(/^\/ca\/.*/) ||
    path.match(/^\/uk/) ||
    path.match(/^\/kr/) ||
    path.match(/^\/ae/)
  ) {
    return removeRegionPrefix(path)
  }

  return path
}

export const getNavigatePath = (path: string, search: string, hash: string) => {
  let targetPath = getGlobalPath(path)
  //remove trailing slash of path
  targetPath = removeTrailingSlash(targetPath)
  const navigatePath = targetPath + search + hash
  return navigatePath === "" ? "/" : navigatePath
}

export const navigateToRegion = (
  region: string,
  pathname: string,
  search: string,
  hash: string
) => {
  let navigatePath = getNavigatePath(pathname, search, hash)

  if (pathname.includes("/prime")) {
    navigatePath = navigatePath.split("?")[0]
  }

  if (region === "global") {
    navigate(navigatePath ? navigatePath : "/", { replace: true })
  } else {
    navigate(`/${region}` + navigatePath, { replace: true })
  }
}
