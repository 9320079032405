import { useEffect } from "react"
import { useTranslation } from "react-i18next"

import { LowerRegions } from "@/configs/region"
import { useCookies } from "react-cookie"

//NOTE acc. WR-665, on EEA site, only English is preserved
export const usePreserveEnglish = (region: LowerRegions) => {
  const [cookies, setCookie] = useCookies(["locale"])
  const { i18n } = useTranslation()
  const { language: currentLanguage } = i18n

  useEffect(() => {
    if (region === "eea") {
      if (currentLanguage !== "en" || cookies["locale"] !== "en") {
        setTimeout(() => {
          i18n.changeLanguage("en")
        })
        setCookie("locale", "en", {
          path: "/",
        })
      }

      if (!cookies["locale"]) {
        setTimeout(() => {
          i18n.changeLanguage("en")
        })
        setCookie("locale", "en", {
          path: "/",
        })
      }
    }
  }, [region])
}
