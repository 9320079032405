import { useCallback, useEffect } from "react"
import { useCookies } from "react-cookie"
import { useLocation } from "@reach/router"
import { navigate } from "gatsby"
import { getNavigatePath } from "./utils"
import {
  regionCookieName,
  US,
  EEA,
  SG,
  BR,
  AU,
  TR,
  FR,
  CA,
  IT,
  UK,
  KR,
  AE,
} from "@/configs/region"
import { useUsersRegion } from "./useUserRegion"
import { extendsExpireYears } from "./utils"
import { tokens } from "../TokenPageTemplate/data/list"

export const useGoMatchedRegion = async () => {
  const [cookies, setCookie] = useCookies([regionCookieName])
  const location = useLocation()
  const userRegion = useUsersRegion()

  const goToMatchedRegion = useCallback(() => {
    /**
     * NOTE region cookie is in upper case whereas path is in lower case
     */

    const regionCookie = cookies[regionCookieName]

    const isUsSite = location.pathname.match(/^\/us/)
    const isEEASite = location.pathname.match(/^\/eea/)
    const isSGSite = location.pathname.match(/^\/sg/)
    const isBRSite = location.pathname.match(/^\/br/)
    const isAUSite = location.pathname.match(/^\/au/)
    const isTRSite = location.pathname.match(/^\/tr/)
    const isFRSite = location.pathname.match(/^\/fr/)
    const isCASite =
      location.pathname === "/ca" ||
      location.pathname === "/ca/" ||
      location.pathname.match(/^\/ca\/.*/)
    const isITSite = location.pathname.match(/^\/it/)
    const isUKSite = location.pathname.match(/^\/uk/)
    const isKRSite = location.pathname.match(/^\/kr/)
    const isAESite = location.pathname.match(/^\/ae/)
    const isGlobalSite =
      !isUsSite &&
      !isEEASite &&
      !isSGSite &&
      !isBRSite &&
      !isAUSite &&
      !isTRSite &&
      !isFRSite &&
      !isCASite &&
      !isITSite &&
      !isUKSite &&
      !isKRSite &&
      !isAESite

    // concatenating search string is for uni and research page
    const search = location.search

    const hash = location.hash

    /**
     * NOTE if the page is cms preview, don't redirect no matter what country it is
     * @todo temp workaround, should not include /cms-preview logic here directly, should
     * use env variable in the future
     */
    if (location.pathname.match(/^\/cms-preview/)) return
    // NOTE privacy page should be open to all users, no redirect
    if (location.pathname.match(/^\/privacy/)) return

    if (location.pathname.match(/^\/resubscription/)) return

    //NOTE restricted in SG, EEA(except FR, TR), will force redirect users back to SG, EEA site
    //w/o checking cookie

    const navigatePath = getNavigatePath(location.pathname, search, hash)

    if (userRegion === SG) {
      if (!isSGSite) {
        navigate(`/sg` + navigatePath, { replace: true })
        return
      }
    } else if (userRegion === EEA) {
      if (!isEEASite) {
        navigate(`/eea` + navigatePath, { replace: true })
        return
      }
    } else if (userRegion === CA) {
      if (!isCASite) {
        navigate(`/ca` + navigatePath, { replace: true })
        return
      }
    } else if (userRegion === IT) {
      if (!isITSite) {
        navigate(`/it` + navigatePath, { replace: true })
        return
      }
    } else if (userRegion === UK) {
      if (!isUKSite) {
        navigate(`/uk` + navigatePath, { replace: true })
        return
      }
    } else if (userRegion === KR) {
      if (!isKRSite) {
        navigate(`/kr` + navigatePath, { replace: true })
      }
      return
    } else {
      //check if user has enter region in path
      if (isSGSite) {
        setCookie(regionCookieName, "SG", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isUsSite) {
        setCookie(regionCookieName, "US", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isEEASite) {
        setCookie(regionCookieName, "EEA", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isBRSite) {
        setCookie(regionCookieName, "BR", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isAUSite) {
        setCookie(regionCookieName, "AU", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isTRSite) {
        setCookie(regionCookieName, "TR", {
          expires: extendsExpireYears(99),
          path: "/",
        })
        return
      }

      if (isFRSite) {
        setCookie(regionCookieName, "FR", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isCASite) {
        setCookie(regionCookieName, "CA", {
          expires: extendsExpireYears(99),
          path: "/",
        })

        return
      }

      if (isITSite) {
        setCookie(regionCookieName, "IT", {
          expires: extendsExpireYears(99),
          path: "/",
        })
        return
      }

      if (isUKSite) {
        setCookie(regionCookieName, "UK", {
          expires: extendsExpireYears(99),
          path: "/",
        })
        return
      }

      if (isKRSite) {
        setCookie(regionCookieName, "KR", {
          expires: extendsExpireYears(99),
          path: "/",
        })
        return
      }

      if (isAESite) {
        setCookie(regionCookieName, "AE", {
          expires: extendsExpireYears(99),
          path: "/",
        })
        return
      }

      //navigate to site based on user IP, otherwise go to global site
      if (isGlobalSite) {
        if (regionCookie === SG) {
          navigate(`/sg` + navigatePath, { replace: true })
          return
        } else if (regionCookie === EEA) {
          navigate(`/eea` + navigatePath, { replace: true })
          return
        } else if (regionCookie === CA) {
          navigate(`/ca` + navigatePath, { replace: true })
          return
        } else if (regionCookie === IT) {
          navigate(`/it` + navigatePath, { replace: true })
          return
        } else if (regionCookie === UK) {
          navigate(`/uk` + navigatePath, { replace: true })
        } else if (regionCookie === KR) {
          navigate(`/kr` + navigatePath, { replace: true })
        } else if (
          regionCookie === US ||
          (userRegion === US && !regionCookie)
        ) {
          navigate(`/us` + navigatePath, { replace: true })
          return
        } else if (
          regionCookie === BR ||
          (userRegion === BR && !regionCookie)
        ) {
          navigate(`/br` + navigatePath, { replace: true })
          return
        } else if (
          regionCookie === AU ||
          (userRegion === AU && !regionCookie)
        ) {
          navigate(`/au` + navigatePath, { replace: true })
          return
        } else if (
          regionCookie === TR ||
          (userRegion === TR && !regionCookie)
        ) {
          navigate(`/tr` + navigatePath, { replace: true })
          return
        } else if (
          regionCookie === FR ||
          (userRegion === FR && !regionCookie)
        ) {
          navigate(`/fr` + navigatePath, { replace: true })
          return
        } else if (
          regionCookie === AE ||
          (userRegion === AE && !regionCookie)
        ) {
          navigate(`/ae` + navigatePath, { replace: true })
          return
        } else {
          return
        }
      }
    }
  }, [userRegion, location.pathname])

  useEffect(() => {
    const userAgent = navigator.userAgent
    const googlebotPattern = /Googlebot/i
    const isHeadlessBrowser = () => {
      return (
        typeof navigator !== "undefined" &&
        navigator.webdriver &&
        navigator.webdriver === true
      )
    }
    const tokenSlugs = tokens
    if (
      location.pathname.match(/^\/tncacceptance_cardcrostaking/) ||
      location.pathname.match(/^\/cms-preview\/legal-docs/) ||
      location.pathname.match(/^\/offer/) ||
      location.pathname.match(/^\/resubscription/) ||
      location.pathname.match(/^\/tncacceptance_us/) ||
      location.pathname.match(/^\/us\/rewardsplus/) ||
      location.pathname.match(/^\/prime\/emailform/) ||
      location.pathname.match(/^\/ben-shapiro/) ||
      location.pathname.match(/^\/korea-launch/) ||
      location.pathname.match(/^\/miami_moon_fan2024/) ||
      location.pathname.match(/^\/miami_moon_gp2024/) ||
      tokenSlugs.filter(slug =>
        location.pathname.match(new RegExp(`^/${slug}`))
      ).length > 0 ||
      !userRegion ||
      googlebotPattern.test(userAgent) ||
      isHeadlessBrowser()
    ) {
      return
    }
    goToMatchedRegion()
  }, [location.pathname, goToMatchedRegion, userRegion])
}
