import { useEffect } from "react"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next"
import { languages } from "@/i18n/config"

// NOTE after mounted, read language cookie and set it
export const useLocaleCookie = () => {
  const [cookies] = useCookies(["locale"])
  const { i18n } = useTranslation()

  useEffect(() => {
    const locale = cookies["locale"]

    if (languages.includes(locale)) {
      Promise.resolve("").then(() => {
        i18n.changeLanguage(locale)
      })
    }
  }, [])
}
